import QRCode from "react-qr-code"

interface QRCodeWrapperProps {
    value: string
    id: string
    hidden?: boolean
    size?: number
}

const QRCodeWrapper = ({
    value,
    id,
    hidden = false,
    size = 250
}: QRCodeWrapperProps) => {
    return <QRCode
        display={hidden ? 'none' : 'inherit'}
        id={id}
        value={value}
        bgColor={"#ffffff"}
        fgColor={"#000000"}
        size={size}
        level={'M'}
    />
}

export default QRCodeWrapper