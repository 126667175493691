import { TFunction } from 'react-i18next'

enum Languages {
  POLISH = 'pl',
  ENGLISH = 'en',
  ROMANIAN = 'ro',
}

export const getLocale = (t: TFunction<'common', undefined>): string => {
  const language = t('current_language')
  switch (language) {
    case Languages.POLISH:
      return 'pl'
    case Languages.ENGLISH:
      return 'enGB'
    case Languages.ROMANIAN:
      return 'ro'
    default:
      return 'enGB'
  }
}
