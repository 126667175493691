import { useCurrentUser } from 'shared/hooks'
import { getRequestHeaders } from 'config'
import { LoadingStatus } from 'shared/types'
import { useState } from 'react'

export const useDownloadFile = () => {
  const { auth } = useCurrentUser()
  const [loading, setLoading] = useState<LoadingStatus>(LoadingStatus.Idle)

  const download = async (url: string) => {
    setLoading(LoadingStatus.Pending)
    try {
      const headers = await getRequestHeaders(auth)

      const response = await fetch(url, {
        headers: { ...headers, 'Content-Type': 'application/json' },
      })

      if (!response.ok) {
        throw new Error(`HTTP error ${response.status}`)
      }

      const blob = await response.blob()
      setLoading(LoadingStatus.Succeeded)
      return blob
    } catch (e) {
      setLoading(LoadingStatus.Failed)
      throw e
    }
  }

  return { download, loading }
}
