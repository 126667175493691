import { LocalDateTime } from '@js-joda/core'
import {
  convertToTimezoneDate,
  convertToTimezoneDateString,
  formatDuration,
  stringToLocalDate,
} from './dates'
import { intervalToDuration } from 'date-fns'
import { TFunction } from 'react-i18next'

export const formatFromTo = (
  from: string,
  to: string,
  timezone: string,
  t: TFunction<'common', undefined>
): { from: string; to: string; duration: string } => {
  const localDateStart = stringToLocalDate(from)
  const localDateEnd = stringToLocalDate(to)
  const localStart = convertToTimezoneDate(from, timezone)
  const localEnd = convertToTimezoneDate(to, timezone)
  const isDateFullDay = (date: LocalDateTime) =>
    date.hour() === 0 && date.minute() === 0
  const fullDateMode = isDateFullDay(localStart) && isDateFullDay(localEnd)
  return {
    from: convertToTimezoneDateString(from, timezone, {
      hideOffset: true,
      onlyDate: fullDateMode,
    }),
    to: convertToTimezoneDateString(to, timezone, {
      hideOffset: true,
      onlyDate: fullDateMode,
      ...(fullDateMode && {
        dayOffset: -1,
      }),
    }),
    duration: formatDuration(
      intervalToDuration({
        start: localDateStart,
        end: localDateEnd,
      }),
      t
    ),
  }
}

export const formatFromToString = (
  from: string,
  to: string,
  timezone: string,
  t: TFunction<'common', undefined>
): string => {
  const { from: _from, to: _to, duration } = formatFromTo(from, to, timezone, t)
  return _from === _to
    ? `${_from} (${duration})`
    : `${_from} - ${_to} (${duration})`
}
