export const downloadSvg = (args: {
  elementId: string
  fileName?: string
  padding?: number
}) => {
  const { elementId } = args
  const fileName = args.fileName ?? 'QRcode'
  const padding = args.padding ?? 40
  const svg = document.getElementById(elementId)
  if (!svg) return
  const svgData = new XMLSerializer().serializeToString(svg)
  const canvas = document.createElement('canvas')
  const ctx = canvas.getContext('2d')
  if (!ctx) return
  const img = new Image()
  img.onload = () => {
    canvas.width = img.width + 2 * padding
    canvas.height = img.height + 2 * padding
    ctx.fillStyle = 'white'
    ctx.fillRect(0, 0, canvas.width, canvas.height)
    ctx.drawImage(img, padding, padding)
    const jpgFile = canvas.toDataURL('image/jpeg', 1)
    const downloadLink = document.createElement('a')
    downloadLink.download = fileName
    downloadLink.href = `${jpgFile}`
    downloadLink.click()
  }
  img.src = `data:image/svg+xml;base64,${btoa(svgData)}`
}
